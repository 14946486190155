export enum ClientTicketState {
    Closed = 'Closed',
    Open = 'Open',
    ClosedByClient = 'ClosedByClient',
    Resolving = 'Solved',
    Working = 'Working',
    Unknown = ''
}

export enum ProviderTicketState {
    Unknown = 0,
    Open = 1,
    Working = 2,
    Solved = 3,
    Closed = 4
}

export const ClientTicketStateItems: TicketState[] = [
    {
        code: ClientTicketState.Open,
        description: 'Aperto',
        closed: false
    },
    {
        code: ClientTicketState.Working,
        description: 'In lavorazione',
        closed: false
    },
    {
        code: ClientTicketState.Resolving,
        description: 'In risoluzione',
        closed: false
    },
    {
        code: ClientTicketState.Closed,
        description: 'Chiuso',
        closed: true
    },
    {
        code: ClientTicketState.ClosedByClient,
        description: 'Chiuso cliente',
        closed: true
    },
];

export const ProviderTicketStateItems: TicketState[] = [
    {
        code: ProviderTicketState.Open,
        description: 'Aperto',
        closed: false
    },
    {
        code: ProviderTicketState.Working,
        description: 'In lavorazione',
        closed: false
    },
    {
        code: ProviderTicketState.Solved,
        description: 'Risolto',
        closed: false
    },
    {
        code: ProviderTicketState.Closed,
        description: 'Chiuso',
        closed: true
    }
];

export class TicketState {
    code: string | number;
    description: string;
    closed: boolean;

    static parse(state: ProviderTicketState | ClientTicketState) {
        return ClientTicketStateItems.find(x => x.code === state) || ProviderTicketStateItems.find(x => x.code === state);
    }
}


export enum TicketArchived {
    Archived = 'all',
    NotArchived = 'notArchived'
}

export enum TicketSearchDefaultOptions {
    ToMe = 'toMe',
    NotAssigned = 'UNASSIGNED',
    Internal = 'IN',
    All = 'All'
}
