export interface AclGroup {
    aclGroupId: number;
    name: string;
    description: string;
    permissions: AclGroupPermission[];
    users: UserAclGroup[];
}

export interface AclGroupPermission {
    aclGroupPermissionId: number;
    permission: AclPermission;
    aclGroupId: number;
}

export interface UserAclGroup {
    userAclGroupId: number;
    userId: number;
    aclGroupId: number;
}

/**
 * Lato WS è una enumerazione. La descrizione è definita tramite attributo Display
 */
export interface AclPermissionDescription {
    id: AclPermission;
    description: string;
    category: string;
}

export enum AclPermission {
    GenerateTokens = 1,
    AclManagement = 2,
    OldSettings = 3,
    SwitchUser = 4,
    QueueList = 5,
    ViewActiveInvoices = 50,
    ViewPassiveInvoices = 51,
    EditXmlInvoices = 52,
    ViewClientsList = 100,
    ViewFullClientsList = 101,
    ExportFullClientsListToCsv = 102,
    LoginToClientAreaAsClient = 103,
    ViewAssociatedClientsList = 104,
    EditClientsFlags = 105,

    AddAndRemoveClientContracts = 106,
    EditDirigentialClients = 107,
    EditAdministrtiveBlockClients = 108,
    EditZucchettiContracts = 109,
    ViewAllAssociatedClientsList = 110,

    ViewTicketsList = 150,
    RefreshTicketProvider = 151,
    SendTicketToProvider = 152,
    ReadTicketToProvider = 153,
    SendTicketMessageNotificationToApp = 154,
    TicketClosingCheckProcedure = 155,
    ReadContractPasswords = 156,
    ReadContractElements = 157,
    DeleteTicketChat = 180,
    CreateTicketChat = 181,
    PhoenixTicketManagement = 190,

    TicketContentSearchNoLimit = 191,
    SendCreditCardLink = 200,
    SendRecourrentPaymentToCreditCard = 201,
    UnpaidManagement = 250,
    Diary = 300,
    Todo = 301,
    Logs = 302,
    AclPlayground = 303,
    ViewOrdersList = 350,

    ViewContractsList = 400,
    ReadContractLineDetailFromProvider = 401,
    EditContractAnnotation = 402,
    EditContractNotes = 403,
    AddContractNotes = 404,
    SetContractParent = 405,

    ReadDynamicDns = 450,
    WriteDynamicDns = 451,
    EditDynamicDnsTtl = 452,

    ListAllUsers = 500,
    SaveUser = 501,

    ViewRemoteDevices = 600,
    EditRemoteDevices = 601,
    RunCommandRemoteDevices = 602,
    SynchronizeRemoteDevices = 603,

    SignalRNotificationsEnabled = 700,
    BeActiveSmsNotifications = 701,
    PhpJobsWarningNotifications = 702,
    GenericYeastarSmsNotification = 703,

    UserLogged = 1000,
    TwoFactorsAuthentication = 1001,
    SkipTwoFactorsAuthentication = 1002,

    ViewDevicesMap = 1050,

    EditFastDialNumbers = 1100,
}
