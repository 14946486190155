import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentTypePipe} from './document-type.pipe';
import {TicketTypePipe} from './ticket-type.pipe';
import {SanitizeClientNamePipe} from './sanitize-client-name.pipe';
import {ProviderPipe} from './provider.pipe';
import {TicketStatePipe} from './ticket-state.pipe';
import {AppDatePipe} from './app-date.pipe';
import {LinkParserPipe} from './link.pipe';
import {SplitEmailsPipe} from './split-emails.pipe';
import {PascalCasePipe} from './pascal-case.pipe';
import {HtmlNewLinesPipe} from './html-new-lines.pipe';
import {ContractStateExpandedPipe} from './contract-state-expanded.pipe';
import {ContractStatePipe} from './contract-state.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import {TicketCodePipe} from './ticket-code.pipe';
import {TicketSidePipe} from './ticket-side.pipe';
import {HighlightWordPipe} from './highlight-word.pipe';
import {AppDurationPipe} from './app-duration.pipe';
import {ScheduledTypePipe} from './scheduled-type.pipe';
import {AppTimePipe} from './app-time.pipe';
import {WeekdayPipe} from './weekday.pipe';
import {EasyCastUserTypePipe} from './easy-cast-user-type.pipe';
import {EasyCastContractStatePipe} from './easy-cast-contract-state.pipe';
import {EasyCastLicenseTypePipe} from './easy-cast-license-type.pipe';
import { ClientTypePipe } from './client-type.pipe';
import { TrimPipe } from './trim.pipe';
import {MacAddressPipe} from './mac-address.pipe';

@NgModule({
    declarations: [
        DocumentTypePipe,
        EasyCastUserTypePipe,
        TicketTypePipe,
        SanitizeClientNamePipe,
        ProviderPipe,
        TicketStatePipe,
        AppDatePipe,
        LinkParserPipe,
        ScheduledTypePipe,
        HtmlNewLinesPipe,
        SplitEmailsPipe,
        PascalCasePipe,
        ContractStateExpandedPipe,
        ContractStatePipe,
        TicketCodePipe,
        SafeHtmlPipe,
        TicketSidePipe,
        HighlightWordPipe,
        AppDurationPipe,
        AppTimePipe,
        WeekdayPipe,
        EasyCastContractStatePipe,
        EasyCastLicenseTypePipe,
        ClientTypePipe,
        TrimPipe,
        EasyCastLicenseTypePipe,
        MacAddressPipe
    ],
    exports: [
        DocumentTypePipe,
        EasyCastUserTypePipe,
        TicketTypePipe,
        SanitizeClientNamePipe,
        ScheduledTypePipe,
        ProviderPipe,
        TicketStatePipe,
        AppDatePipe,
        AppDurationPipe,
        LinkParserPipe,
        HtmlNewLinesPipe,
        SplitEmailsPipe,
        PascalCasePipe,
        ContractStateExpandedPipe,
        ContractStatePipe,
        TicketCodePipe,
        SafeHtmlPipe,
        TicketSidePipe,
        HighlightWordPipe,
        AppTimePipe,
        WeekdayPipe,
        EasyCastContractStatePipe,
        EasyCastLicenseTypePipe,
        ClientTypePipe,
        TrimPipe,
        EasyCastLicenseTypePipe,
        MacAddressPipe
    ],
    imports: [
        CommonModule
    ]
})
export class PipesModule {
}
