import {BaseService, Body, DELETE, GET, Headers, Multipart, Path, POST, Response, ResponseType} from 'ts-retrofit';
import {ACCEPT_JSON, ACCEPT_PDF} from '../../config';
import {ToData} from '../../rest-api-hydratator-decorators';
import {User} from '../../../../models/user';
import {ClientTicketMessage} from '../../../../models/tickets/client/client-ticket-message';
import {TicketReplyMessageRequest} from '../../../../models/requests/ticket-form/ticket-reply-message-request';
import {ticketsForm} from './ticket-form-service-constants';

export class TicketMessagesApiService extends BaseService {
    @DELETE(`/${ticketsForm}/{groupId}/client/{ticketId}/messages/{messageId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(User)
    async deleteClientMessage(
        @Path('groupId') groupId: number,
        @Path('ticketId') ticketId: number,
        @Path('messageId') messageId: number,
        // TODO: sostituire con BodyRequest<string>
        @Body message: { logMessage: string }): Promise<Response<ClientTicketMessage>> {
        return <Response>{};
    }

    @POST(`/${ticketsForm}/{groupId}/client/{ticketId}/messages`)
    @Headers({...ACCEPT_JSON})
    async sendClientReplyMessage(
        @Path('groupId') groupId: number,
        @Path('ticketId') ticketId: number,
        @Body replyRequest: TicketReplyMessageRequest): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${ticketsForm}/{groupId}/provider/{ticketId}/messages`)
    @Headers({...ACCEPT_JSON})
    async sendProviderReplyMessage(
        @Path('groupId') groupId: number,
        @Path('ticketId') ticketId: number,
        @Body replyRequest: TicketReplyMessageRequest): Promise<Response<boolean>> {
        return <Response>{};
    }

    @GET(`/${ticketsForm}/{groupId}/client/{ticketId}/messages/{messageId}/attachments/{attachmentId}`)
    @Headers({...ACCEPT_PDF})
    @ResponseType('blob')
    async getClientAttachment(
        @Path('groupId') groupId: number,
        @Path('ticketId') ticketId: number,
        @Path('messageId') messageId: number,
        @Path('attachmentId') attachmentId: number
    ): Promise<Response<Blob>> {
        return <Response>{};
    }

    @GET(`/${ticketsForm}/{groupId}/provider/{ticketCode}/messages/{messageId}/attachments/{attachmentId}`)
    @Headers({...ACCEPT_PDF})
    @ResponseType('blob')
    async getProviderAttachment(
        @Path('groupId') groupId: number,
        @Path('ticketId') ticketCode: string,
        @Path('messageId') messageId: number,
        @Path('attachmentId') attachmentId: number
    ): Promise<Response<Blob>> {
        return <Response>{};
    }

    @GET(`/${ticketsForm}/client/messages/{messageId}`)
    @Headers({...ACCEPT_PDF})
    async getClientTicketMessageById(
        @Path('messageId') messageId: number
    ): Promise<Response<ClientTicketMessage>> {
        return <Response>{};
    }

}
